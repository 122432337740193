html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*="language-"]):before,
code:not([class*="language-"]):after {
  content: "`";
}
code[class*="language-"] {
  padding: 0;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 0px;
  /* border: 5px solid #fff; */
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  /* border: 4px solid #f4f4f4; */
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.scrollbar {
  height: 75vh;
  width: 30vw;
  min-width: 150px;
  overflow-y: scroll;
}

.overflow {
  min-height: 100vh;
}

.playlist-container {
  width: 30%;
  border-radius: 0px !important;
  position: fixed !important;
  right: 0px !important;
}

.frame-left {
  width: 70%;
  max-width: 1920px;
  padding-right: 0;
}

.frame-right {
  width: 30%;
}

.frame-left.collapsed-list {
  width: 100%;
  max-width: 1920px;
  padding-right: 0;
}

.frame-right.collapsed-list {
  width: 0%;
  visibility: hidden;
}

.collapsed-button {
  position: fixed;
  right: -43px;
  top: 80px;
  padding: 20px;
  z-index: 9999999;
}

.side-bar-accordion:first-of-type {
  padding-top: 0px !important;
}
.side-bar-accordion:last-child {
  padding-bottom: 0px !important;
}

.side-bar-accordion {
  border-radius: 4px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  margin: 2px !important;
  margin-left: 8px !important;
}

.page-theme-light .side-bar-accordion,
.page-theme-light .mobile-side-bar-accordion {
  border-bottom: 1px solid #e8e8ec !important;
  background-color: #efeff5 !important;
}
.page-theme-dark .side-bar-accordion,
.page-theme-dark .mobile-side-bar-accordion {
  border-bottom: 1px solid #414569 !important;
  background-color: #414569 !important;
}

.side-bar-accordion-active {
  border-radius: 3px !important;
}

.page-theme-light .side-bar-accordion-active {
  background-color: #dadae2 !important;
  /* border: 1px solid #e2e2e4 !important; */
}

.page-theme-dark .side-bar-accordion-active {
  background-color: #4e527e !important;
  /* border: 1px solid #414569 !important; */
}

.side-bar-accordion-summary {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
}

.side-bar-accordion-summary-container {
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 100%;
}

.side-bar-accordion-summary-container.active h6 {
  font-weight: 700 !important;
}

.side-bar-accordion-details {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 5px !important;
}
.side-bar-accordion-details-container {
  display: block;
  justify-content: space-between;
}

.side-bar-accordion h6,
.mobile-side-bar-accordion h6,
h6.lesson-title-chapter-index {
  font-size: 0.6em !important;
  line-height: 1;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

/**
 *
*/

.mobile-side-bar-accordion:first-of-type {
  padding-top: 0px !important;
}
.mobile-side-bar-accordion:last-child {
  /* padding-bottom: 0px !important; */
}

.mobile-side-bar-accordion {
  border-radius: 4px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin: 2px !important;
  margin-bottom: 10px !important;
  border: 0px !important;
  border-bottom: 1px solid #e8e8ec !important;
  /*border: 1px solid #e8e8ec !important;
  border-radius: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important; */
}
.mobile-side-bar-accordion-active {
  border-radius: 3px !important;
}

.page-theme-light .mobile-side-bar-accordion-active {
  background-color: #dadae2 !important;
  /* border: 1px solid #e2e2e4 !important; */
}

.page-theme-dark .mobile-side-bar-accordion-active {
  background-color: #4e527e !important;
  /* border: 1px solid #414569 !important; */
}

.mobile-side-bar-accordion-summary {
  padding-left: 4px !important;
  padding-right: 0px !important;
}

.mobile-side-bar-accordion-summary-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mobile-side-bar-accordion-summary-container.active h6 {
  font-weight: 700 !important;
}

.mobile-side-bar-accordion-details {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 5px !important;
}

.mobile-side-bar-accordion-details-container {
  display: block;
  justify-content: space-between;
}
/**
*
*
*/

.lesson-item {
  display: flex;
  width: auto;
  min-height: 50px;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-left: 0px;
  cursor: pointer;
  /* border: 1px solid #e8e8ec; */
  border-radius: 4px;
  margin: 4px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.lesson-item-container {
  display: flex;
  padding-left: 10px !important;
}

/* .lesson-item:hover {
  background-color: #e6e9ed;
}

.lesson-item.active {
  background-color: #e6e9ed;
} */

.page-theme-light .lesson-item.active,
.page-theme-light .lesson-item:hover {
  background-color: #dadae2;
}
.page-theme-dark .lesson-item.active,
.page-theme-dark .lesson-item:hover {
  background-color: #4e527e;
}

.lesson-item.active p {
  font-weight: bold !important;
}

.mobile-lesson-item {
  display: flex;
  width: auto;

  padding-bottom: 12px;
  padding-top: 12px;
  margin-left: 0px;
  cursor: pointer;
  padding-left: 0px;
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 0px;
}

.mobile-lesson-item-container {
  display: flex;
  padding-left: 10px !important;
}

.page-theme-light .mobile-lesson-item.active,
.page-theme-light .mobile-lesson-item:hover {
  background-color: #e6e9ed;
}
.page-theme-dark .mobile-lesson-item.active,
.page-theme-dark .mobile-lesson-item:hover {
  background-color: #414569;
}

.mobile-lesson-item.active p {
  font-weight: bold !important;
}

.mobile-chk-lesson-status {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mobile-chk-lesson-status.checked,
.chk-lesson-status.checked {
  color: #09af32 !important;
}

.mobile-lesson-title {
  display: inline-flex;
}

.chk-lesson-status {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.lesson-title {
  display: inline-flex;
}
.lesson-length {
  margin-top: 4px;
  font-size: 0.6rem;
  display: flex;
  align-items: normal;
}

.mobile-lessons-nav {
  position: fixed;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  box-sizing: border-box;
  z-index: 99999;
}

.mobile-lessons-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 60px;
}
.mobile-lessons-nav-left,
.mobile-lessons-nav-right {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  cursor: pointer;
}
.mobile-lessons-nav-left-disabled,
.mobile-lessons-nav-left-disabled svg {
  opacity: 0.3;
  cursor: default;
}
.mobile-lessons-nav-right-disabled,
.mobile-lessons-nav-right-disabled svg {
  opacity: 0.3;
  cursor: default;
}

.mobile-comments-tabs button {
  padding: 15px 25px !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  z-index: 0;
  /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.audio-player-wrapper {
  position: relative;
  /* padding-top: 65px;
  margin-top: 20px; */
}
.react-audio-player {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 50px;
  padding-right: 50px;
}

.lesson-title.active {
  font-weight: 600 !important;
}

.lesson-text-container-soon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lesson-text-container .cover,
.lesson-text-container-soon .cover {
  width: 100%;
  /* max-width: 800px; */
  height: 45vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.lesson-video-wrapper {
  background-color: black;
  min-height: 45vh;
}

.page-theme-dark .lesson-video-wrapper {
  background-color: #30334e;
  min-height: 45vh;
}
.page-theme-light .lesson-video-wrapper {
  background-color: #f7f7f9;
  min-height: 45vh;
}
@media only screen and (max-width: 768px) {
  .page-theme-light .lesson-video-wrapper {
    background-color: #f7f7f9;
    min-height: 25vh;
  }
  .page-theme-dark .lesson-video-wrapper {
    background-color: #30334e;
    min-height: 25vh;
  }
}

.lesson-audio-wrapper {
  /* background-color: black; */
  min-height: 40vh;
}
.lesson-text-wrapper {
  /* background-color: black; */
  min-height: 40vh;
}

.lesson-audio-container {
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.lesson-audio-container .cover {
  width: 100%;
  height: 45vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.lesson-video-container {
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.lesson-video-container.video-loading {
  align-items: center;
  text-align: center;
  display: grid;
  margin: 50;
  min-height: 45vh;
}

.lesson-video-container.ar-default {
  width: 56vw;
}

.lesson-video-container.ar_1_6-9 {
  width: 56vw;
}

.lesson-video-container.ar_full {
  width: 100vw;
}

.lesson-video-container.ar_4-3 {
  width: 40vw;
}

.video-loading-spinner {
  display: flex;
  justify-content: center;
  height: 60vh;
  width: 70%;
  align-items: center;
  position: absolute;

  z-index: 99997;
}
.video-layout-top-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
}

.attachments-info-chip {
  font-size: 0.7rem !important;
  margin-right: 1px !important;
  height: 20px !important;
  border-radius: 4px !important;
  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */
}
.next-lesson {
  position: relative;
  right: 10px;
  top: 6px;
  float: right;
  height: 0px;
  z-index: 99999;
}

.last-lesson {
  position: relative;
  right: 40px;
  top: 6px;
  float: right;
  height: 0px;
  z-index: 99999;
}
.last-lesson .MuiAlert-message {
  color: white !important;
}
.footer {
}

.footer .copyright-container {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .footer .copyright-container {
    display: flex;
    justify-content: flex-start;
  }
}

.footer .copyright {
  font-size: 0.7rem;
}

.soon-chip {
  margin-left: 0px;
  font-size: 13px !important;
  font-weight: 400 !important ;
}
.soon-info {
  color: #797979;
  padding: 10px;
  text-align: center;
  display: block;

  padding-top: 100px;
  padding-bottom: 100px;
  position: inherit;
}

.countdown-card {
  color: #797979;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  position: inherit;
  margin-bottom: 50px;
}

.page-theme-dark .countdown-card {
  color: #ebebeb;
}

.page-theme-light .countdown-card {
  color: #797979;
}

.countdown-text {
  text-align: center;
  margin-bottom: 10px;
}
.countdown-text h2 {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .frame-left {
    width: 0%;
    max-width: 1920px;
    padding-right: 0;
  }

  .frame-right {
    width: 100%;
  }
  .playlist-container {
    width: 100%;
  }
  .scrollbar {
    display: inline-table;
    height: 75vh;
    width: 100vw;
    min-width: 150px;
    overflow-y: scroll;
  }
  .collapsed-button {
    position: fixed;
    right: -43px;
    top: 300px;
    padding: 20px;
    z-index: 9999999;
  }
  .soon-info {
    color: #fff;
    padding: 10px;
    text-align: center;
    display: block;

    padding-top: 120px;
    position: inherit;
  }
  l.soon-info h2 {
    font-size: 1.2rem;
  }

  .lesson-text-container-soon .cover {
    width: 100%;
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .lesson-video-container.ar-default {
    /* width: 100vw; */
    width: 100%;
  }

  /* .lesson-video-container.ar_1_6-9 {
   
   width: 100%;
  } */

  .lesson-video-container.ar_4-3 {
    /* width: 100vw; */
    width: 100%;
  }

  .lesson-video-wrapper {
    min-height: auto;
  }
}

.comment-date {
  font-size: 0.8rem !important;
  margin-left: 10px !important;
  margin-right: 10px;
}

.comment-vote:hover {
  color: red;
}
.comment-vote-self {
  color: red;
}
.comment-vote-self:hover {
}

.comment-delete-icon {
  cursor: pointer;
}
.comment-delete-icon:hover {
  cursor: pointer;
  color: red;
}

.comment-votes-badge {
  margin-top: -10px !important;
  margin-left: 0px !important;
}

.comment-votes-badge span {
  font-size: 0.5rem !important;
  height: 15px !important;
  min-width: 15px !important;
  border-radius: 20px;
}

.comments-container {
  padding: 1.25rem;
  padding-top: 1.75rem;
}
.comments-avatar {
  margin-top: 0.75rem;
  margin-left: 0rem;
}
.comments-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.comments-actions-container {
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  margin-bottom: 0.75rem;
}

.app-bar-logo {
  height: 50px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .comments-container {
    padding: 0rem;
    padding-top: 1.55rem;
  }
  .comments-avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .comments-actions-container {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    margin-bottom: 0.75rem;
  }

  .app-bar-logo {
    height: 45px;
  }
}

.lesson-title-chapter {
  font-size: 0.75rem;
  margin-top: 0;
}

.lesson-title-lesson {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 0;
}

.countdown-table-hours td {
  font-weight: 700;
  text-align: center;
  font-size: 28px;
}

.countdown-table-labels td {
  font-weight: 700;
  text-align: center;
  font-size: 10px;
}

.lesson-container-soon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lesson-container-soon .cover {
  width: 100%;
  max-width: 800px;
  height: 30vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/******************************************************/
/******************************************************/
/******************************************************/
/******************************************************/
.page-theme-dark .lesson-html-content {
  filter: brightness(150%);
}
.lesson-html-content a,
.lesson-html-content a:visited {
  color: #03a9f4 !important;
  text-decoration: underline;
}

.lesson-html-content a:hover {
  color: #2cb7f7 !important;
  text-decoration: underline;
}

.lesson-html-content img {
  max-width: 100%;
}

.lesson-html-content p {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.panel-description {
  font-size: 1rem;
  line-height: 1.5;
}

.page-theme-light .chapter-image-title,
.page-theme-light .chapter-image-title-mobile {
  color: #4c4e64 !important;
}
.page-theme-light .chapter-image-title h6,
.page-theme-light .chapter-image-title-mobile h6 {
  color: #4c4e6480 !important;
}

.page-theme-dark .chapter-image-title,
.page-theme-dark .chapter-image-title-mobile {
  color: #e1e1f6 !important;
}
.page-theme-dark .chapter-image-title h6,
.page-theme-dark .chapter-image-title-mobile h6 {
  color: #e1e1f680 !important;
}

.page-theme-light .lesson-title-chapter-index {
  color: #4c4e6480 !important;
}
.page-theme-dark .lesson-title-chapter-index {
  color: #e1e1f680 !important;
}

.chapter-image-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  font-size: 0.9rem;
}
.chapter-image-title .chapter-image {
  height: 56px;
  border-radius: 4px;
  margin-right: 8px;
}

.chapter-image-title-mobile {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  font-size: 0.9rem;
}
.chapter-image-title-mobile .chapter-image-mobile {
  height: 56px;
  border-radius: 4px;
  margin-right: 8px;
}

.chapter-progress-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.chapter-progress-container .chapter-progress {
  width: 150px;
  height: 5px !important;
}
.chapter-progress-container .chapter-progress-text {
  margin-right: 10px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .panel-description {
    font-size: 0.8rem;
    line-height: 1;
  }
}
.no-comments-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-comments {
  padding: 0px;
  border-radius: 60%;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-comments svg {
  font-size: 5rem !important;
}
.page-theme-light .no-comments {
  background-color: #e8e8ec;
}
.page-theme-dark .no-comments {
  background-color: #414569;
}
.user-menu {
  z-index: 9999;
}
.comments-buttons {
  max-width: calc(92% + 20px);
  display: flex;
  justify-content: flex-end;
}
.soon-chip-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.text-start-continue {
  font-size: 16px !important;
}
